.nav-bar {
    display: flex;
    flex-direction: column;
    background-color: #e9dfd9;
    font-family: 'Agrandir-Regular';
   
    //big screen
    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        height: 140px;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;

        .logo-section {
            display: flex;
        }

        .logo {
            display: flex;
            flex-direction: row;
            
            img {
                display: flex;
                width: 60px;
                height: auto;
            }
        }
    
        nav {
            display: flex;
            height: 100%;
            
            a {
                font-size: 14px;
                color: #4d4d4e;
                display: flex;
                flex-direction: column;
                align-items: center; 
                justify-content: center;
                text-decoration: none;
                margin-right: 40px; 
                i {
                    transition: all 0.3s ease-in-out;
                }
                
                &:hover {
                    color: black;
                    
                    svg {
                        opacity: 0;
                    }
                    
                    &:after {
                        opacity: 1;
                    }
                }
                
                &:after {
                    display: flex;
                    text-align: center;
                    content: '';
                    font-size: 12px;
                    font-weight: 600;
                    letter-spacing: 2px;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }
                
                
                &:first-child {
                    &:after {
                        content: 'HOME';
                    }
                }
                
            }
            
            a.about-link:after {
                content: 'ABOUT';
            }
            
            a.contact-link:after {
                content: 'CONTACT';
            }
            
            a.service-link:after {
                content: 'SERVICES';
            }
            
            a.active {
                svg {
                    color: rgb(34, 34, 34);
                }
            }
        }
    
        ul {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            list-style: none;
            li {
                margin-right: 20px;
                a {
                    font-size: 15px;
                    
                    &:hover svg {
                        color: black;
                    }
                }
            }
        }   

        .hamburger {
          display: none;
        }

        .mobileNav {
            display: none;
        }
    }
    
    //small screen
    @media (max-width: 767px) {
        .mainNav{
            display:none;
        }

        .div {
            display: flex;
        } 
    
        
        .hamburger {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            cursor: pointer;
            height: 50px;
            width: 50px;
            border: 1px solid transparent;
            background-color:transparent ;
            color: black;
            top: 16px;
            right: 16px;
            z-index: 10;
            transition: border 0.3s ease;
            
        }
        
        .hamburger:active {
            outline: 5px solid transparent;
        }

        .hamburger::after,
        .hamburger::before {
        content: '';
        height: 20px;
        border-left: 1px solid var(--darklight);
        transform: rotate(90deg);
        margin: -3px;
        transition: transform 0.3s ease;
        }

        .hamburger--active::after {
        transform: rotate(-135deg);
        }
        .hamburger--active::before {
        transform: rotate(-45deg);
        }
        
        .logo-section {
            display: flex;
            justify-content: center;
            padding: 12px;
            margin-bottom: 8rem;           
        }
        
        .logo {
            display: flex;
            justify-content: center;
            
            img {
                display: flex;
                width: 70%;
            }
        }

        .mobileNav {
            display: flex;
            flex-direction: column;
            position: fixed;
            top: 80px;
            width: 100%;
            background: #DAC9BE;
            right: 0;
            justify-content: center;
            align-items: center;
            transform: translateX(100%);
            transition: transform 0.5s ease;
            z-index: 11;
          }
          
          .mobileNav--active {
            transform: translateX(0);
            z-index: 11;
          }
          
          .mobileNav a {
            color: #5f5f5f;
            font-size: 2rem;
            margin: 0.3em;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            transition: border-bottom 1s ease;
            background: #DAC9BE;
          }
          
          .mobileNav a:hover {
            border-bottom: 1px solid #DAC9BE;
          }
          
          .mobileNav a:focus, .main__nav a:active{
            border: 5px solid var(--red001)
          }

        ul {
            display: none;
        }
  
    }
}
