.footer {
    display: flex;
    justify-content: center; 
    align-items: center; 
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
  
    @media (max-width: 767px) {
      display:none;
    }
  }