.home-page {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

     //big screen
     @media (min-width: 768px) { 
        .text-zone {
            width: 50%;
        }
     }

    
    .text-zone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        animation: fadeIn 1s 1.5s backwards;
       
    }

    .image-zone {
        display: flex;
        animation: fadeIn 1s 1.5s backwards;
        
        img {
            width: 80%;
            max-width: 300px;
        }
    }
    
    h1 {
        display: flex;
        color: black;
        font-size: 63px;
        margin: 0;
        font-family: 'League-Spartan';
        letter-spacing: -6px;
        
        &::before {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }
        
        &::after 
        {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
        
        
        
        img {
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }
    h2 {
        display: flex;
        align-items: center;
        color: black;
        text-align: left;
        font-size: 20px;
        font-family: 'Agrandir-Regular';
        letter-spacing: 1px;
        font-weight: 400;
        animation: fadeIn 1s 1.8s backwards;
    }
    
    .flat-button{
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: 'Agrandir-Regular';
        text-decoration: none;
        padding: 10px 12px;
        border: 1px solid black;
        margin-top: 25px;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        &:hover {
            background: #DAC9BE;
            color: #333;
        }
    }
    
    @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 
        padding: 10%;
        
        h1 {
            display: none;
        }

        h2 {
            font-size: 16px;
            display: flex;
            text-align: center;
        }

        .text-zone {
            align-items: center;
        }

        .image-zone{
            justify-content: center;
            margin-top: 5%;
        }
    }
}