

$primary-color: black;

@import 'animate.css';

@font-face {
  font-family: 'Helvetica Neue';
  src: url(./assets/fonts/helvetica-neu.ttf) format('ttf');
}

@font-face {
  font-family: 'La Belle Aurore';
  src: url(./assets/fonts/LaBelleAurore.woff2) format('woff2');
}

@font-face {
  font-family: 'Coolvetica';
  src: url(./assets/fonts/CoolveticaRg-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Agrandir';
  src: url('./assets/fonts/Agrandir-GrandLight.otf') format('opentype'); 
}

@font-face {
  font-family: 'Agrandir-GrandHeavy';
  src: url('./assets/fonts/Agrandir-GrandHeavy.otf') format('opentype'); 
}

@font-face {
  font-family: 'Agrandir-TextBold';
  src: url('./assets/fonts/Agrandir-TextBold.otf') format('opentype'); 
}

@font-face {
  font-family: 'Agrandir-Regular';
  src: url('./assets/fonts/Agrandir-Regular.otf') format('opentype'); 
}

@font-face {
  font-family: 'League-Spartan';
  src: url('./assets/fonts/leaguespartan-bold.woff') format('woff'); 
}

input,
textarea {
  font-family: 'Agrandir-ThinItalic';
}

