.consultancy-page {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 80%;
    
     //big screen
     @media (min-width: 768px) { 
        .top-text-zone {
            height: 250px;
            padding: 12px;
        }

        .top-text-zone-indah {
            height: 250px;
            padding: 12px; 
        }

        .text-zone-side {
            padding: 12px;
        }

        .text-zone-indah {
            padding: 12px;
        }

        .image-zone {
            width: 50%;
            padding: 12px;
        }
     }

    .top-text-zone {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
       
    }

    .text-zone {
        display: flex;
        flex-direction: column;
        animation: fadeIn 1s 1.5s backwards;
    }

    .text-zone-side {  
        display: flex;
        animation: fadeIn 1s 1.5s backwards;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }

    .top-text-zone-indah {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #f8f8f8; 
        border: 1px solid #e0e0e0;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    
    .text-zone-indah {  
        width: 50%;
        display: flex;
        align-items: center;
        text-align: left;
        animation: fadeIn 1s 1.5s backwards;
    }

    .image-zone {
        display: flex;
        animation: fadeIn 1s 1.6s backwards;
        justify-content: center;
        align-items: center;
        
        img {
            width: 80%;
            max-width: 300px;
        }
    }

    h1 {
        color: black;
        font-size: 63px;
        margin: 0;
        font-family: 'League-Spartan';
        letter-spacing: -6px;

        &::before {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            opacity: 0.6;
        }

        &::after 
        {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }
    h2 {
        color: black;
        font-size: 20px;
        font-family: 'Agrandir-Regular';
        letter-spacing: 1px;
        font-weight: 600;
        animation: fadeIn 1s 1.8s backwards;
    }
    
    h3 {
        color: black;
        font-weight: 400;
        font-size: 12px;
        font-family: 'Agrandir-Regular';
        letter-spacing: 2.5px;
        animation: fadeIn 1s 1.8s backwards;
    }

    //small screen
    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;

        div {
            display: flex;
            flex-direction: column;
        }

        
        h1 {
            display: none;
        }
        
        h2 {
            font-size: 16px;
            font-weight: 600;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        h3 {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            text-align: center;
        }

        .top-text-zone-indah {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 12px; 
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            background-color: #f8f8f8; 
            border: 1px solid #e0e0e0;
            height: auto;
        }

        .text-zone-indah {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
         }

        
        .top-text-zone {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
        }

        .text-zone {
            width: 100%;
            display: flex; 
            flex-direction: column;
            align-items: center;
        }
        
        .image-zone{
            align-items: center;
            justify-content: center;
            width: 100%;
          
        }
    }
}