.about-page {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 80%;

         //big screen
         @media (min-width: 768px) { 
            .text-zone {
                width: 50%;
            }
         }

    .text-zone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start; 
        animation: fadeIn 1s 1.5s backwards;
    }

    h1 {
        color: black;
        font-size: 63px;
        margin: 0;
        font-family: 'League-Spartan';
        letter-spacing: -6px;

        &::before {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            position: absolute;
            margin-top: -40px;
            left: 15px;
            opacity: 0.6;
        }

        &::after 
        {
            content: '';
            font-family: 'League-Spartan';
            color: black;
            font-size: 18px;
            position: absolute;
            margin-top: 18px;
            margin-left: 20px;
            animation: fadeIn 1s 1.7s backwards;
            opacity: 0.6;
        }
    }
    h2 {
        color: black;
        margin-top: 20px;
        font-size: 20px;
        font-family: 'Agrandir-Regular';
        letter-spacing: 1px;
        font-weight: 400;
        animation: fadeIn 1s 1.8s backwards;
    }

    @media (max-width: 767px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center; 

        
        h1 {
            display: none;
        }
        
        h2 {
            font-size: 16px;
            display: flex;
            flex-direction: column;
            text-align: center;
        }
        
        .text-zone {
            align-items: center;
        }
        
        .image-zone{
            justify-content: center;
            margin-top: 5%;
        }
    }
}