html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font: 300 11px/1.4 'Agrandir-ThinItalic';
  color: #444;
  background: #e9dfd9;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font: 400 20px/1.4 'Agrandir-Regular';
  color: #323232;
  text-decoration: none;
}