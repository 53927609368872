.page {
    width: 100%;
    height: 100%;
    display: flex;

}

.top-tags {
    display: flex;
    align-items: center;
}

.tags {
    color: black;
    opacity: 0.6;
    bottom: 0;
    font-size: 18px;
    font-family: 'Agrandir-Regular';
}

.bottom-tag-html {
    margin-left: -20px;
}

.container {
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0 auto;
    z-index: 1;
    transform-style: preserve-3d;
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
    display: flex;
    flex-direction: row; 
    justify-content: center; 
    padding:  5%;
}

.logo {
    white-space: nowrap;
}